import { createContext } from 'react';
import { createBatcher } from '../components/AnimateSharedLayout/utils/batcher.js';

var SharedLayoutContext = createContext(createBatcher());
/**
 * @internal
 */
var FramerTreeLayoutContext = createContext(createBatcher());
function isSharedLayout(context) {
    return !!context.forceUpdate;
}

export { FramerTreeLayoutContext, SharedLayoutContext, isSharedLayout };
